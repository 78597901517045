import React, { useEffect, useState } from 'react';
import { CardHeader, Row } from 'reactstrap';

import BackButton from '../../components/BackButton';
import ClientAPI, { OfficeAPI } from '../../api/Client';
import { Navigate, useParams } from 'react-router-dom';
import AgencySalesGraph from '../Components/AgencySalesGraph/AgencySalesGraph';
import { getUserInfo } from '../../connect/auth';

function Office() {
  const { officeId } = useParams();
  const {
    is_super_admin: isSuperAdmin,
    is_admin: isAdmin,
    user_id: userId,
  } = getUserInfo();

  if (!isSuperAdmin && !isAdmin[officeId]) {
    console.log('Forbidden page, not enough rights');
    return <Navigate to="/dashboard" replace />;
  }

  const [officeInfo, setOfficeInfo] = useState({});
  // const [agencyId, setAgencyId] = useState(null);
  // const [testInfo, setTestInfo] = useState(null);

  useEffect(() => {
    getOfficeInfo();
    // getAgencyId();
  }, []);

  // function getAgencyId() {
  //   ClientAPI.getJSON(`/users/${userId}/`)
  //     .then((res) => {
  //       if (res.offices) {
  //         // setTestInfo(res.offices);
  //         const office = res.offices.find(
  //           (o) => o.id === parseInt(officeId, 10)
  //         );
  //         if (office) {
  //           const agencyId = office.agency_id;
  //           setAgencyId(String(agencyId));
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching agency ID:', error);
  //     });
  // }

  function getOfficeInfo() {
    OfficeAPI.getInfo(officeId).then((r) => setOfficeInfo(r));
  }

  const { state, address, email, name, agency_id } = officeInfo;

  return (
    <div className="animated fadeIn">
      <Row>
        <div className="col-md-12">
          <div className="card card-accent-primary">
            <CardHeader>
              <h3>
                {name} 
                {/* Данные для отладки agency_id */}
                {/*{isSuperAdmin && `(${agencyId}, ${userId})`}*/}
              </h3>
            </CardHeader>
            <div className="card-body">
              <div className="row">
                <label className="text-right col-md-2">Область:</label>
                <p className="col-md-8">{state}</p>
              </div>
              <div className="row">
                <label className="text-right col-md-2">Адрес:</label>
                <p className="col-md-8">{address}</p>
              </div>
              <div className="row">
                <label className="text-right col-md-2">Email:</label>
                <p className="col-md-8">{email}</p>
              </div>
              {agency_id && <AgencySalesGraph agencyId={agency_id} />}

              {/* Данные для отладки и тестирования agencyId */}
              {/*{isSuperAdmin &&
              testInfo &&
              testInfo.map((o, idx) => (
                <p key={o.id}>
                  {idx}: id - {o.id}; agency - {o.agency_id}; test -{' '}
                  {Number(o.agency_id)}; test2 - {parseInt(o.agency_id, 10)} ;
                  test3 - {String(o.agency_id)}; test4 - {o.agency_id};
                  `active - {String(o.active)}; address - {o.address}; city -{' '}
                  {o.city}; name - {o.name}`<br/>
                  {JSON.stringify(o)}
                </p>
              ))}*/}
              <BackButton />
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
}

export default Office;
