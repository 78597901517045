import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';

import { POSITIVE_ACTION_STATUSES, AgenciesAPI } from '../../../api/Client';

export default class FilterAgencies extends Component {
  constructor(props) {
    super(props);

    this.fetchFilterCountries = this.fetchFilterCountries.bind(this);
    this.fetchFilterCurators = this.fetchFilterCurators.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.formTextChange = this.formTextChange.bind(this);
    this.handleAgencyType = this.handleAgencyType.bind(this);
    this.handleCountry = this.handleCountry.bind(this);
    this.handleAgencyStatus = this.handleAgencyStatus.bind(this);
    this.handleAgencyWithExperience = this.handleAgencyWithExperience.bind(this);
    this.startDateChange = this.startDateChange.bind(this);
    this.endDateChange = this.endDateChange.bind(this);

    const filter = this.loadFilter();

    this.state = filter || {
      agency_type: '',
      countries: [],
      country: '',
      curators: [],
      curator: '',
      fp_city: '',
      fp_index: '',
      fp_status: '',
      id: '',
      opened_date__gte: '',
      opened_date__lte: '',
      public_name: '',
      with_experience: null,
    };
    this.props.onFilter(this.state);
  }

  componentDidMount() {
    this.fetchFilterCountries();
    this.fetchFilterCurators();
  }

  fetchFilterCountries() {
    let status;
    AgenciesAPI.fetchList(1, {}, 'filter/countries/')
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            countries: r || [],
          });
        }
      });
  }

  fetchFilterCurators() {
    let status;
    AgenciesAPI.fetchList(1, {}, 'filter/curators/')
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            curators: r || [],
          });
        }
      });
  }

  _get_valid_serialize_state(data) {
    const fields = [
      'agency_type',
      'country',
      'countries',
      'curator',
      'curators',
      'fp_city',
      'fp_index',
      'fp_status',
      'id',
      'opened_date__gte',
      'opened_date__lte',
      'public_name',
    ];
    const validated_data = {};

    for (let field in data) {
      if (~fields.indexOf(field)) {
        if (field == 'opened_date__gte' || field == 'opened_date__lte') {
          validated_data[field] = data[field] ? data[field] : '';
        } else {
          validated_data[field] = data[field];
        }
      }
    }
    return validated_data;
  }

  saveFilter(state) {
    const valid_state = this._get_valid_serialize_state(state);
    localStorage.setItem('agencies_search_filter', JSON.stringify(valid_state));
  }

  resetFilter() {
    localStorage.removeItem('agencies_search_filter');
  }

  loadFilter() {
    if (localStorage.getItem('agencies_search_filter')) {
      const data = JSON.parse(localStorage.getItem('agencies_search_filter'));
      return this._get_valid_serialize_state(data);
    }
  }

  handleReset() {
    this.props.setPage({ selected: 0 });
    this.props.handleSortReset();
    this.setState(
      {
        agency_type: '',
        country: '',
        curator: '',
        fp_city: '',
        fp_index: '',
        fp_status: '',
        id: '',
        opened_date__gte: '',
        opened_date__lte: '',
        public_name: '',
        with_experience: null,
      },
      this.handleFilterUpdate
    );
  }

  handleSubmit() {
    this.props.setPage({ selected: 0 });
    this.props.handleSortReset();
    this.saveFilter(this.state);
    this.props.onFilter(this.state);
  }

  handleFilterUpdate() {
    this.saveFilter(this.state);
    this.props.onFilter(this.state);
  }

  handleAgencyType(agency_type) {
    this.setState({ agency_type });
  }
  handleCountry(country) {
    this.setState({ country });
  }
  startDateChange(opened_date__gte) {
    this.setState({ opened_date__gte });
  }

  endDateChange(opened_date__lte) {
    this.setState({ opened_date__lte });
  }

  handleAgencyStatus(fp_status) {
    this.setState({ fp_status });
  }

  handleAgencyWithExperience(with_experience) {
    this.setState({ with_experience });
  }

  formTextChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  render() {
    const {
      agency_type,
      countries,
      country,
      id,
      fp_city,
      fp_index,
      fp_status,
      public_name,
      opened_date__gte,
      opened_date__lte,
    } = this.state;
    const AGENCY_TYPE_OPTIONS = [
      { value: 1, label: 'Фирменный' },
      { value: 2, label: 'Франчайзи' },
      { value: 3, label: 'Субагент' },
      { value: 4, label: 'Фрилансер' },
    ];

    const COUNTRY_OPTIONS = countries.length
      ? countries.map((c) => {
          return { value: c.value, label: c.label };
        })
      : [];

    const FP_STATUS_OPTIONS = [
      { value: 'accepted', label: 'да' },
      { value: 'rejected', label: 'нет' },
      { value: 'waiting', label: 'думаем' },
    ];
    const WITH_EPERIENCE_OPTIONS = [
      { value: true, label: 'да' },
      { value: false, label: 'нет' },
    ];

    return (
      <Card className="filter-container">
        <CardHeader>
          <strong>Поиск агентств</strong>
        </CardHeader>
        <CardBody>
          <p style={{ marginBottom: '0px' }}>Дата регистрации: </p>
          <div className="filter-row">
            <div className="filter-field date-from">
              <label htmlFor="leadDate" className="col-form-label">
                Дата от
              </label>
              <DatePicker
                locale={ru}
                dateFormat="dd.MM.yyyy"
                selected={opened_date__gte}
                selectsStart
                startDate={opened_date__gte}
                endDate={opened_date__lte}
                onChange={this.startDateChange}
                className="d-block"
                placeholderText="Дата начала"
              />
            </div>
            <div className="filter-field date-to">
              <label htmlFor="leadDate" className="col-form-label">
                Дата до
              </label>
              <DatePicker
                locale={ru}
                dateFormat="dd.MM.yyyy"
                selected={opened_date__lte}
                selectsEnd
                startDate={opened_date__gte}
                endDate={opened_date__lte}
                onChange={this.endDateChange}
                placeholderText="Дата окончания"
              />
            </div>
            <div className="filter-field">
              <label htmlFor="fpStatus" className="mr-2">
                Регистрация
              </label>
              <Select
                id="fpStatus"
                placeholder="Регистрация"
                name="fpStatus"
                value={fp_status}
                isSearchable={false}
                onChange={this.handleAgencyStatus}
                options={FP_STATUS_OPTIONS}
                isClearable
              />
            </div>
            {this.state.curators && (
              <div className="filter-field">
                <label htmlFor="curator" className="mr-2">
                  Куратор в ФСУ
                </label>
                <Select
                  id="agency-type-selector"
                  placeholder="Куратор в ФСУ"
                  name="curator"
                  value={this.state.curator}
                  isSearchable={true}
                  onChange={(value) => this.setState({curator: value})}
                  options={this.state.curators}
                  isClearable
                />
              </div>
            )}
          </div>
          <p style={{ marginBottom: '0px' }}>Найти агента</p>
          <div className="filter-row">
            <div className="filter-field">
              <label htmlFor="id" className="mr-2">
                CRM Id
              </label>
              <input
                type="text"
                className="form-control"
                id="id"
                placeholder="CRM ID"
                name="id"
                value={id}
                onChange={this.formTextChange}
              />
            </div>
            <div className="filter-field">
              <label htmlFor="fp_index" className="mr-2">
                ФСУ Id
              </label>
              <input
                type="text"
                className="form-control"
                id="fp_index"
                placeholder="ФСУ ID"
                name="fp_index"
                value={fp_index}
                onChange={this.formTextChange}
              />
            </div>
            <div className="filter-field">
              <label htmlFor="public_name" className="mr-2">
                Название
              </label>
              <input
                type="text"
                className="form-control"
                id="public_name"
                placeholder="Название"
                name="public_name"
                value={public_name}
                onChange={this.formTextChange}
              />
            </div>
            <div className="filter-field">
              <label htmlFor="agencyType" className="mr-2">
                Тип агентства
              </label>
              <Select
                id="agencyType"
                placeholder="Тип агентства"
                name="agencyType"
                value={agency_type}
                isSearchable={false}
                onChange={this.handleAgencyType}
                options={AGENCY_TYPE_OPTIONS}
                isClearable
              />
            </div>
          </div>
          <div className="filter-row">
            <div className="filter-field">
              <label htmlFor="country" className="mr-2">
                Страна
              </label>
              <Select
                id="agency-type-selector"
                placeholder="Страна"
                name="country"
                value={country}
                isSearchable={false}
                onChange={this.handleCountry}
                options={COUNTRY_OPTIONS}
                isClearable
              />
            </div>
            <div className="filter-field">
              <label htmlFor="fp_city" className="mr-2">
                Город
              </label>
              <input
                type="text"
                className="form-control"
                id="fp_city"
                placeholder="Город"
                name="fp_city"
                value={fp_city}
                onChange={this.formTextChange}
              />
            </div>
            <div className="filter-field">
              <label htmlFor="agencyWithExperience" className="mr-2">
                С опытом
              </label>
              <Select
                id="agencyWithExperience"
                placeholder="С опытом"
                name="agencyWithExperience"
                value={this.state.with_experience}
                onChange={this.handleAgencyWithExperience}
                options={WITH_EPERIENCE_OPTIONS}
                isClearable
              />
            </div>
          </div>
          <CardFooter>
            <button
              type="submit"
              className="btn btn-sm btn-primary"
              onClick={this.handleSubmit}
            >
              <i className="fa fa-dot-circle-o"></i>
              Поиск
            </button>
            <button
              type="reset"
              className="btn btn-sm btn-danger"
              onClick={this.handleReset}
            >
              <i className="fa fa-ban"></i>
              Очистить
            </button>
          </CardFooter>
        </CardBody>
      </Card>
    );
  }
}
