import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import ru from 'react-phone-input-2/lang/ru.json';
import { ContactsAPI } from '../../../api/Client/Client';
import {
  Alert,
  CloseButton,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Progress,
} from 'reactstrap';
import { MdClose } from 'react-icons/md';
import LeadFoundClient from './LeadFoundClient';

function LeadInputPhone({ value, onChange, onClientChoose, disabled }) {
  const [searchValue, setSearchValue] = useState('');
  const [infoPopup, setInfoPopup] = useState('');
  const [matchingResults, setMatchingResults] = useState([]);
  const [isDataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchValue.length > 7) {
        searchPhoneValue();
      } else setMatchingResults([]);
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchValue]);

  function searchPhoneValue() {
    setDataLoading(true);
    ContactsAPI.get('by-phone', { value: searchValue }).then((result) => {
      if (result) {
        setDataLoading(false);
        if (result.length === 0) {
          openInfoPopup('Нет результатов');
          setMatchingResults([]);
          return;
        }
        setMatchingResults(result);
        openInfoPopup(`Найдены совпадения`);
      } else {
        openInfoPopup('Нет результатов');
        setMatchingResults([]);
        return;
      }
    });
  }

  function openInfoPopup(message) {
    setInfoPopup(message);
    setTimeout(() => {
      setInfoPopup('');
    }, 3000);
  }

  function resetState() {
    setSearchValue('');
    setMatchingResults([]);
    setDataLoading(false);
  }

  function handleClientChoose(client, type) {
    onClientChoose(client, type);
    resetState();
  }

  function handleChangePhone(phone) {
    onChange({ target: { name: 'phone', value: phone } });
    setSearchValue(phone);
  }

  return (
    <div className="form-group">
      <label htmlFor="phone">Телефон</label>
      <PhoneInput
        country={'ru'}
        localization={ru}
        onlyCountries={['ru', 'kz', 'by']}
        countryCodeEditable={false}
        value={value}
        onChange={handleChangePhone}
        disabled={disabled}
      />
      {isDataLoading && (
        <Progress animated color="info" value={100} style={{ margin: 0 }} />
      )}
      {infoPopup && <Alert className="alert alert-info">{infoPopup}</Alert>}
      {matchingResults?.length > 0 && (
        <ListGroup className="search-results" style={{ position: 'relative' }}>
          <CloseButton
            className="btn-danger"
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
              width: 20,
              height: 20,
              zIndex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 20,
              padding: 0,
              border: 'none',
              cursor: 'pointer',
              borderRadius: 5,
            }}
            onClick={() => setMatchingResults([])}
            title="Скрыть результаты поиска"
          >
            <MdClose />
          </CloseButton>
          {matchingResults.map((result) =>
            result.persons.length > 0 || result.tourists_fp.length > 0 ? (
              <ListGroupItem
                key={result.value}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <ListGroupItemHeading>{result.value}</ListGroupItemHeading>
                <ListGroup style={{ paddingLeft: 0 }}>
                  {result.persons?.length > 0 ? (
                    <LeadFoundClient
                      handleClientChoose={handleClientChoose}
                      clients={result.persons}
                      name={'Потенциальные'}
                      clientType="person"
                      linkTo="/persons"
                      inputType="phone"
                    />
                  ) : null}
                  {result.tourists_fp?.length > 0 ? (
                    <LeadFoundClient
                      handleClientChoose={handleClientChoose}
                      clients={result.tourists_fp}
                      name={'Постоянные'}
                      clientType="tourist"
                      linkTo="/tourists"
                      inputType="phone"
                    />
                  ) : null}
                </ListGroup>
              </ListGroupItem>
            ) : null
          )}
        </ListGroup>
      )}
    </div>
  );
}

export default LeadInputPhone;
