import * as FileSaver from 'file-saver';
import {getCurrentOfficeId, fetchToken} from '../../connect/auth';

export const POSITIVE_ACTION_STATUSES = {
    'create': 201,
    'update': 200,
    'partial_update': 200,
    'destroy': 204,
    'list': 200,
    'retrieve': 200,
};

class Client {

    constructor(resource_url) {
        this.resource_url = resource_url;
    }

    encodeObject(obj) {
        let filters = [];
        if (obj) {
            Object.keys(obj).forEach(function (k) {
                const value = obj[k];
                if (Array.isArray(value)) {
                    value.forEach(function (v) {
                        filters.push(`${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
                    });
                } else {
                    filters.push(`${encodeURIComponent(k)}=${encodeURIComponent(value)}`);
                }
            });
        }
        return `&${filters.join('&')}`;
    }

    async getAuthToken(login, password) {
        const r = await fetch('/frontend/api/auth/', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
            body: this.encodeObject({username: login, password: password}),
        });
        return await r.json();
    }

    fetchAuthToken(login, password) {
        return fetch('/frontend/api/auth/', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
            body: this.encodeObject({username: login, password: password}),
        });
    }

    update(url, data) {
        return fetch(`/frontend/api${url}/`, {
            method: 'PATCH',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }

    deactivateTourist(url) {
        return fetch(`/frontend/api/${url}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: ''
        });
    }

    create(url, data) {
        return fetch(`/frontend/api${url}/`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }

    getJSON(url) {
        return fetch(`/frontend/api${url}`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `JWT ${fetchToken()}`,
                'office': getCurrentOfficeId(),
            },
        }).then(function (response) {
            if (response.ok) {
                return response.json();
            } else {
                return {};
            }
        });
    }

    fetchJSON(url) {
        return fetch(`/frontend/api${this.resource_url}${url}`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `JWT ${fetchToken()}`,
                'office': getCurrentOfficeId(),
            },
        });
    }

    fetchList(p = 1, params = {}, url_suffix = '', s) {
        if (!params) {
            params = {};
        }

        params.page = p;
        params.strikes = s;
        let encodedGetParams

        if (params.strikes) {
            encodedGetParams = this.encodeObject(params);
        } else {
            const {strikes, ...withoutStrikesParams} = params
            encodedGetParams = this.encodeObject(withoutStrikesParams);
        }
        return this.fetchJSON(`${url_suffix}?${encodedGetParams}`);
    }

    fetchOffices() {
        return fetch(`/frontend/api/users/offices/`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `JWT ${fetchToken()}`,
            },
        });
    }

    fetchBalance() {
        return fetch(`/frontend/api/cashaccounts/balance/`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `JWT ${fetchToken()}`,
                'office': getCurrentOfficeId(),
            },
        });
    }

    fetchViberBalance() {
        return fetch(`/frontend/api/cashaccounts/ext_balance/viber/`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `JWT ${fetchToken()}`,
                'office': getCurrentOfficeId(),
            },
        });
    }

}

const APIClient = new Client("");


class ResourceClient extends Client {

    getList(p = 1, params = {}, url_suffix = '') {
        if (!params) {
            params = {};
        }
        params.page = p;
        const encodedGetParams = this.encodeObject(params);
        return super.getJSON(`${this.resource_url}${url_suffix}?${encodedGetParams}`);
    }

    getInfo(pk) {
        return super.getJSON(`${this.resource_url}${pk}/`);
    }

    add(pk, data) {
        return fetch(`/frontend/api${this.resource_url}${pk}/`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        }).then((r) => r.json());
    }

    modify(pk, data) {
        return fetch(`/frontend/api${this.resource_url}${pk}/`, {
            method: 'PATCH',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        }).then((r) => {return r.json()});
    }

    create(data) {
        return fetch(`/frontend/api${this.resource_url}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        }).then(
            r => r.json(),
        );
    }

    modify(pk, data) {
        return fetch(`/frontend/api${this.resource_url}${pk}/`, {
            method: 'PATCH',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }

    extModify(url, data) {
        // ..
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }

    delete(pk) {
        return fetch(`/frontend/api${this.resource_url}${pk}/`, {
            method: 'DELETE',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
        });
    }
}

class EventsAPIResourse extends ResourceClient {
    get_events_month(month) {
        return fetch(`/frontend/api${this.resource_url}?month__gte=${month}&month__lte=${month}`, {
            method: 'GET',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
            },
        }).then((r) => r.json());
    }

    get_events_year(year) {
        return fetch(`/frontend/api${this.resource_url}?year=${year}`, {
            method: 'GET',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
            },
        }).then((r) => r.json());
    }
}

class AbandonedCustomersAPIResource extends ResourceClient {

    deleteItems(params) {
        const encodedGetParams = this.encodeObject(params);
        return fetch(`/frontend/api${this.resource_url}multipledeleting/?${encodedGetParams}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'office': getCurrentOfficeId(),
            },
        });
    }

    mailigen(data, filter = false) {
        let encodedGetParams;
        if (filter && filter.type == 'abandonedcustomers') {
            encodedGetParams = this.encodeObject({});
        } else if (!localStorage.getItem('abandonedcustomers_filter')) {
            encodedGetParams = this.encodeObject({});
        }
        if (!filter) {
            encodedGetParams = this.encodeObject(JSON.parse(localStorage.getItem('abandonedcustomers_filter')));
        }
        return fetch(`/frontend/api${this.resource_url}campaign/?${encodedGetParams}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }
}

class EmployeeAPIResource extends ResourceClient {
}

class PersonAPIResource extends ResourceClient {
    getIssues(tourist, p) {

        let params = {};
        params.page = p;

        const encodedGetParams = this.encodeObject(params);
        return fetch(`/frontend/api${this.resource_url}${tourist}/issues/?${encodedGetParams}`, {
            method: 'GET',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'office': getCurrentOfficeId(),
            },
        }).then((r) => r.json());
    }

    mailigen(data) {
        if (!localStorage.getItem('persons_filter')) {
            return {};
        }
        const encodedGetParams = this.encodeObject(JSON.parse(localStorage.getItem('persons_filter')));
        return fetch(`/frontend/api${this.resource_url}campaign/?${encodedGetParams}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }

    oneMessage(data, filters) {
        if (!filters) {
            return {};
        }
        const encodedGetParams = this.encodeObject(filters);
        return fetch(`/frontend/api${this.resource_url}campaign/?${encodedGetParams}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }

    deletePersons(id) {
        const encodedGetParams = this.encodeObject({id});
        return fetch(`/frontend/api${this.resource_url}multipledeleting/?${encodedGetParams}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'office': getCurrentOfficeId(),
            },
        });
    }
}

class LeadAPIResoure extends ResourceClient {
    issue(pk, data) {
        return fetch(`/frontend/api${this.resource_url}${pk}/create_issue/`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        }).then(
            r => r.json(),
        );
    }

    createIssue(pk, data) {
        return fetch(`/frontend/api${this.resource_url}${pk}/create_issue/`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }

    mailigen(data) {
        if (!localStorage.getItem('leads_filter')) {
            return {};
        }
        const encodedGetParams = this.encodeObject(JSON.parse(localStorage.getItem('leads_filter')));
        return fetch(`/frontend/api${this.resource_url}campaign/?${encodedGetParams}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }

    deleteLeads(id) {
        const encodedGetParams = this.encodeObject({id});
        return fetch(`/frontend/api${this.resource_url}multipledeleting/?${encodedGetParams}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'office': getCurrentOfficeId(),
            },
        });
    }

    fetchReport(filters) {
        const encodedGetParams = this.encodeObject(filters);
        return fetch(`/frontend/api${this.resource_url}generate_excel_report/?${encodedGetParams}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'office': getCurrentOfficeId(),
                responseType: 'blob',
            },
        });
    }
}

class OfficeAPIResoure extends ResourceClient {
    getTemplateInfo(office_id) {
        return fetch(`/frontend/api${this.resource_url}${office_id}/template_data/`, {
            method: 'GET',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'office': getCurrentOfficeId(),
            },
        }).then((r) => r.json());
    }
}

class OrderFpAPIResource extends ResourceClient {
    mailigen(data) {
        if (!localStorage.getItem('tourists_filter')) {
            return {};
        }
        const encodedGetParams = this.encodeObject(JSON.parse(localStorage.getItem('tourists_filter')));
        return fetch(`/frontend/api${this.resource_url}campaign/?${encodedGetParams}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }
}

class TouristFpAPIResource extends ResourceClient {

    getIssues(tourist, p) {

        let params = {};
        params.page = p;

        const encodedGetParams = this.encodeObject(params);
        return fetch(`/frontend/api${this.resource_url}${tourist}/issues/?${encodedGetParams}`, {
            method: 'GET',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'office': getCurrentOfficeId(),
            },
        }).then((r) => r.json());
    }

    getOrders(tourist, p) {

        let params = {};
        params.page = p;

        const encodedGetParams = this.encodeObject(params);
        return fetch(`/frontend/api${this.resource_url}${tourist}/orders/?${encodedGetParams}`, {
            method: 'GET',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'office': getCurrentOfficeId(),
            },
        }).then((r) => r.json());
    }

    getFiles(tourist, p) {

        let params = {};
        params.page = p;

        const encodedGetParams = this.encodeObject(params);
        return fetch(`/frontend/api${this.resource_url}${tourist}/documents/?${encodedGetParams}`, {
            method: 'GET',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'office': getCurrentOfficeId(),
            },
        }).then((r) => r.json());
    }

    mailigen(data, filter = false) {
        let encodedGetParams;
        if (filter && filter.type == 'all') {
            encodedGetParams = this.encodeObject({});
        } else if (filter && filter.type == 'tags') {
            encodedGetParams = this.encodeObject({page: 1, tag: filter.tags});
        } else if (!localStorage.getItem('touristsfp_filter')) {
            return {};
        }
        if (!filter) {
            encodedGetParams = this.encodeObject(JSON.parse(localStorage.getItem('touristsfp_filter')));
        }
        return fetch(`/frontend/api${this.resource_url}campaign/?${encodedGetParams}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }

    oneMessage(data, filters) {
        if (!filters) {
            return {};
        }
        const encodedGetParams = this.encodeObject(filters);
        return fetch(`/frontend/api${this.resource_url}campaign/?${encodedGetParams}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }
}

class PassportAPIResource extends ResourceClient {
    create(data) {
        return fetch(`/frontend/api${this.resource_url}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }

    update(pk, data) {
        return super.update(`${this.resource_url}${pk}`, data);
    }
}

class IssuesAPIResource extends ResourceClient {
    create(data) {
        return fetch(`/frontend/api${this.resource_url}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        }).then(
            r => {
                if (r.ok) {
                    return r.json();
                } else {
                    return Promise.reject(r);
                }
            },
        ).catch(err => err.json());
    }

    fetchSourceReport(page = 1, filters) {
        if (!filters) {
            filters = {};
        }
        const encodedGetParams = this.encodeObject(filters);
        return this.fetchJSON(`report-source/?page=${page}${encodedGetParams}`);
    }

    fetchManagersReport(page = 1, filters) {
        if (!filters) {
            filters = {};
        }
        const encodedGetParams = this.encodeObject(filters);
        return this.fetchJSON(`report-managers/?page=${page}${encodedGetParams}`);
    }

    fetchManagerSourceReport(page = 1, filters) {
        if (!filters) {
            filters = {};
        }
        const encodedGetParams = this.encodeObject(filters);
        return this.fetchJSON(`report-managers-source/?page=${page}${encodedGetParams}`);
    }
}

class AnswersAPIResource extends ResourceClient {
    create(data) {
        return fetch(`/frontend/api${this.resource_url}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }
}

class ExcelAPIResourse extends ResourceClient {
    upload(data) {
        // let User = Auth.getUserInfo(Auth.getToken());
        // data.append('tourist', User.user_id);
        return fetch(`/frontend/api${this.resource_url}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'office': getCurrentOfficeId(),
                // 'Content-Type': 'application/json',
            },
            body: data,
        });
    }

    get(pk) {
        let filename = 'document';
        return fetch(`/frontend/api${this.resource_url}${pk}/`, {
            method: 'GET',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                responseType: 'blob',
            },

        }).then(r => {
            filename = r.headers.get('content-disposition') ?
                (r.headers.get('content-disposition').split('=')[1]).replace(/\"/g, '') :
                filename;
            return r.blob();
        }).then(file => {
            FileSaver.saveAs(file, filename);
        });
    }
}

class DocumentsAPIResourse extends ResourceClient {
    upload(data) {
        // let User = Auth.getUserInfo(Auth.getToken());
        // data.append('tourist', User.user_id);
        return fetch(`/frontend/api${this.resource_url}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'office': getCurrentOfficeId(),
                // 'Content-Type': 'application/json',
            },
            body: data,
        }).then((r) => r.json());
    }

    getFileAsBase64(pk) {
        let filename = 'document';
        return fetch(`/frontend/api${this.resource_url}${pk}/`, {
            method: 'GET',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'office': getCurrentOfficeId(),
                'Content-Type': 'application/json',
            },

        }).then((r) => r.json());
    }

    get(pk) {
        let filename = 'document';
        return fetch(`/frontend/api${this.resource_url}${pk}/`, {
            method: 'GET',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'office': getCurrentOfficeId(),
                responseType: 'blob',
            },

        }).then(r => {
            filename = r.headers.get('content-disposition') ?
                (r.headers.get('content-disposition').split('=')[1]).replace(/\"/g, '') :
                filename;
            return r.blob();
        }).then(file => {
            FileSaver.saveAs(file, filename);
        });
    }
}

class TemplatesAPIResource extends ResourceClient {
    getAll() {
        return fetch(`/frontend/api${this.resource_url}full_list/`, {
            method: 'GET',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
        });
    }
}

class MailingAPIResource extends ResourceClient {
    get(pk) {
        return fetch(`/frontend/api${this.resource_url}${pk}/`, {
            method: 'GET',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'text/html',
                'office': getCurrentOfficeId(),
            },
        });
    }

    getEmailPreviewHtml(addressBookId) {
        return fetch(`/frontend/api/campaigns/${addressBookId}/email-preview/`, {
            method: 'GET',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                // 'Accept': 'text/html',
                // 'Content-Type': 'text/html',
                'Cache-control': 'no-cache',
                'office': getCurrentOfficeId(),
            },
        });
    }
}

class SmsAPIResource extends ResourceClient {
}

class TagsAPIResource extends ResourceClient {
}

class UsersAPIResource extends ResourceClient {
    create(data) {
        return fetch(`/frontend/api${this.resource_url}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }

    get(pk) {
        return fetch(`/frontend/api${this.resource_url}${pk}/`, {
            method: 'GET',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
        });
    }
}

class CabinetAPIResource extends ResourceClient {
    comment(id, data) {
        return fetch(`/frontend/api${this.resource_url}${id}/comment/`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }

    create(data) {
        return fetch(`/frontend/api${this.resource_url}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }

    mailigen(data, filter = false) {
        let encodedGetParams;
        if (filter && filter.type == 'cabinet') {
            encodedGetParams = this.encodeObject({});
        } else if (!localStorage.getItem('tourists_cabinet_filter')) {
            encodedGetParams = this.encodeObject({});
        }
        if (!filter) {
            encodedGetParams = this.encodeObject(JSON.parse(localStorage.getItem('tourists_cabinet_filter')));
        }
        return fetch(`/frontend/api${this.resource_url}campaign/?${encodedGetParams}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }
}

class SubscriptionAPIResource extends ResourceClient {
    upload(data) {
        // let User = Auth.getUserInfo(Auth.getToken());
        // data.append('tourist', User.user_id);
        return fetch(`/frontend/api${this.resource_url}import/`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'office': getCurrentOfficeId(),
                // 'Content-Type': 'application/json',
            },
            body: data,
        });
    }

    mailigen(data, filter = false) {
        let encodedGetParams;
        if (filter && filter.type == 'subscription') {
            encodedGetParams = this.encodeObject({});
        } else if (!localStorage.getItem('subscription_filter')) {
            encodedGetParams = this.encodeObject({});
        }
        if (!filter) {
            encodedGetParams = this.encodeObject(JSON.parse(localStorage.getItem('subscription_filter')));
        }
        return fetch(`/frontend/api${this.resource_url}campaign/?${encodedGetParams}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }
}

class MergersAPIResource extends ResourceClient {
    create(data) {
        return fetch(`/frontend/api${this.resource_url}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }
}

class StagingsAPIResource extends ResourceClient {
    mailigen(data, filter = false) {
        let encodedGetParams;
        if (filter && filter.type === 'all') {
            encodedGetParams = this.encodeObject({});
        } else if (filter && filter.type === 'tags') {
            encodedGetParams = this.encodeObject({
                page: 1,
                tag: filter.tags,
                only_person: filter.only_person
            });
        } else if (filter && filter.type === 'personal') {
            encodedGetParams = this.encodeObject(filter);
        } else if (!localStorage.getItem('touristsfp_filter')) {
            return {};
        }
        if (!filter) {
            encodedGetParams = this.encodeObject(JSON.parse(localStorage.getItem('touristsfp_filter')));
        }
        return fetch(`/frontend/api${this.resource_url}campaign/?${encodedGetParams}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }
}

class TelebotAPIResource extends ResourceClient {
}

class TouristListsAPIResource extends ResourceClient {
    mailigen(data, filter = false) {
        let encodedGetParams;
        let addressbooks;
        if (filter && filter.type == 'report') {
            encodedGetParams = this.encodeObject({});
        } else if (!localStorage.getItem('tourists_report_filter')) {
            encodedGetParams = this.encodeObject({});
        }
        if (!filter) {
            encodedGetParams = this.encodeObject(JSON.parse(localStorage.getItem('tourists_report_filter')));
        }
        addressbooks = JSON.parse(localStorage.getItem('tourists_report_address_books'));
        return fetch(`/frontend/api${this.resource_url}${addressbooks}/entries/campaign/?${encodedGetParams}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }

    getStrikes(bookId) {
        return fetch(`/frontend/api/addressbooks/${bookId}/filter/strikes/`, {
            method: 'GET',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
        });
    }
}

class NotificationSendersAPIResource extends ResourceClient {
    create(data) {
        return fetch(`/frontend/api${this.resource_url}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }

    update(pk, data) {
        return super.update(`${this.resource_url}${pk}`, data);
    }
}

class MailigenAPIResource extends ResourceClient {
    create(data) {
        return fetch(`/frontend/api${this.resource_url}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }

    update(pk, data) {
        return super.update(`${this.resource_url}${pk}`, data);
    }
}

class TemplateDataAPIResource extends ResourceClient {
    create(data) {
        return fetch(`/frontend/api${this.resource_url}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }

    update(pk, data) {
        return super.update(`${this.resource_url}${pk}`, data);
    }
}

class SendPulseAPIResource extends ResourceClient {
    create(data) {
        return fetch(`/frontend/api${this.resource_url}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }

    update(pk, data) {
        return super.update(`${this.resource_url}${pk}`, data);
    }
}

class CurrenciesAPIResource extends ResourceClient {
}

class RequestForOrdersAPIResource extends ResourceClient {
}

class CashAccountsAPIResource extends ResourceClient {
    create(data) {
        return fetch(`/frontend/api${this.resource_url}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }
}

class AgenciesAPIResource extends ResourceClient {

    comment(id, data) {
        return fetch(`/frontend/api${this.resource_url}${id}/comment/`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }

    create(data) {
        return fetch(`/frontend/api${this.resource_url}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }

    getSalesData(id) {
        return fetch(`/frontend/api${this.resource_url}${id}/graph-sales/`, {
            method: 'GET',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
        });
    }

    mailigen(data, filter = false) {
        let encodedGetParams;
        if (filter && filter.type == 'franchise') {
            encodedGetParams = this.encodeObject({});
        } else if (!localStorage.getItem('franchise_filter')) {
            encodedGetParams = this.encodeObject({});
        }
        if (!filter) {
            encodedGetParams = this.encodeObject(JSON.parse(localStorage.getItem('franchise_filter')));
        }

        return fetch(`/frontend/api${this.resource_url}campaign/?${encodedGetParams}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }

    merge(data) {
        return fetch(`/frontend/api${this.resource_url}merge/`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }

    update(pk, data) {
        return super.update(`${this.resource_url}${pk}`, data);
    }
}

class InvoicesAPIResource extends ResourceClient {
    create(data) {
        return fetch(`/frontend/api${this.resource_url}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }
}

class PicturesAPIResource extends ResourceClient {
    send_viber_picture(data) {
        return fetch(`/frontend/api${this.resource_url}send_viber_picture/`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'office': getCurrentOfficeId(),
            },
            body: data,
        });
    }

    send_email_picture(data) {
        return fetch(`/frontend/api${this.resource_url}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'office': getCurrentOfficeId(),
            },
            body: data,
        });
    }

    send_avatar_picture(data) {
        return fetch(`/frontend/api/users/${data.get('id')}/user_info/`, {
            method: 'PATCH',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'office': getCurrentOfficeId(),
            },
            body: data,
        });
    }
}

class SpendingsAPIResource extends ResourceClient {
}

class CalendarApiResource extends ResourceClient {
}

class QuotesAPIResource extends ResourceClient {
    deleteQuote(quote) {
        return fetch(`/frontend/api${this.resource_url}${quote}/`, {
            method: 'DELETE',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
        });
    }

    actualizeQuote(quote) {
        return fetch(`/frontend/api${this.resource_url}${quote}/actualize/`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
        });
    }

    toggleQuoteHideAfterDays2(quote) {
        return fetch(`/frontend/api${this.resource_url}${quote}/toggle-hide-after-days-2/`, {
            method: 'PATCH',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
        });
    }

    toggleQuoteIsActiveOnSite(quote) {
        return fetch(`/frontend/api${this.resource_url}${quote}/toggle-is-active-on-site/`, {
            method: 'PATCH',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
        });
    }

    toggleQuoteNotifyAuthorByTelegram(quote) {
        return fetch(`/frontend/api${this.resource_url}${quote}/toggle-notify-author-by-telegram/`, {
            method: 'PATCH',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
        });
    }

    deleteTour(quote, tour) {
        return fetch(`/frontend/api${this.resource_url}${quote}/tours/${tour}/`, {
            method: 'DELETE',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
        });
    }
}

class OfficeSummaryAPIResource extends ResourceClient {
    renewal(params = null) {
        var url = `/frontend/api${this.resource_url}renewal/`;
        if (params) { url += `?${this.encodeObject(params)}`}
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
        });
    }
}

class FranchiseApplicationAPIResource extends ResourceClient {
    multipleDeleting(id) {
        const encodedGetParams = this.encodeObject({id});
        return fetch(`/frontend/api${this.resource_url}multipledeleting/?${encodedGetParams}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'office': getCurrentOfficeId(),
            },
        });
    }

    mailigen(data, filter = false) {
        let encodedGetParams;
        if (filter && filter.type == 'franchise_leads') {
            encodedGetParams = this.encodeObject({});
        } else if (!localStorage.getItem('franchise_leads_filter')) {
            encodedGetParams = this.encodeObject({});
        }
        if (!filter) {
            encodedGetParams = this.encodeObject(JSON.parse(localStorage.getItem('franchise_leads_filter')));
        }

        return fetch(`/frontend/api${this.resource_url}campaign/?${encodedGetParams}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }

    create(data) {
        return fetch(`/frontend/api${this.resource_url}`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${fetchToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'office': getCurrentOfficeId(),
            },
            body: JSON.stringify(data),
        });
    }
}

class ContactsAPIResource extends ResourceClient {
  async get(type, value) {
    const encodedGetParams = this.encodeObject( value );
    const r = await fetch(
      `/frontend/api${this.resource_url}${type}/?${encodedGetParams}`,
      {
        method: 'GET',
        headers: {
          Authorization: `JWT ${fetchToken()}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          office: getCurrentOfficeId(),
        },
      }
    );
    return await r.json();
  }
}

export const AbandonedCustomersAPI = new AbandonedCustomersAPIResource('/abandoned_customers/');
export const PersonAPI = new PersonAPIResource('/persons/');
export const EmployeeAPI = new EmployeeAPIResource('/employees/');
export const UsersAPI = new UsersAPIResource('/users/');
export const CabinetAPI = new CabinetAPIResource('/site_cabinet/users/');
export const LeadAPI = new LeadAPIResoure('/leads/');
export const OfficeAPI = new OfficeAPIResoure('/offices/');
export const OrderFpAPI = new OrderFpAPIResource('/ordersfp/');
export const TouristFpAPI = new TouristFpAPIResource('/touristsfp/');
export const PassportAPI = new PassportAPIResource('/passports/');
export const EventsAPI = new EventsAPIResourse('/events/');
export const DocumentsAPI = new DocumentsAPIResourse('/tfpdocuments/');
export const ExcelAPI = new ExcelAPIResourse('/excel_files/');
export const IssuesAPI = new IssuesAPIResource('/issues/');
export const IssuesMergersAPI = new IssuesAPIResource('/issues_to_merge/');
export const AnswersAPI = new AnswersAPIResource('/answers/');
export const TemplatesAPI = new TemplatesAPIResource('/templates/');
export const MailingAPI = new MailingAPIResource('/campaigns/');
export const MailigenAPI = new MailigenAPIResource('/mailigen/');
export const SmsAPI = new SmsAPIResource('/mailing_sms/run_campaign/');
export const TagsAPI = new TagsAPIResource('/tags/');
export const MergersAPI = new MergersAPIResource('/mergers/');
export const NotificationSendersAPI = new NotificationSendersAPIResource('/notification_senders/');
export const PersonMergersAPI = new MergersAPIResource('/person_mergers/');
export const TouristMergersAPI = new MergersAPIResource('/tourist_mergers/');
export const RequestTouristNotifyAPI = new MergersAPIResource('/request_notifies/');
export const StagingsAPI = new StagingsAPIResource('/stagings/');
export const TelebotAPI = new TelebotAPIResource('/telegram_users/');
export const TemplateDataAPI = new TemplateDataAPIResource('/template_data/');
export const CalendarApi = new CalendarApiResource('/calendar/');
export const CashAccountsAPI = new CashAccountsAPIResource('/cashaccounts/');
export const InvoicesAPI = new InvoicesAPIResource('/invoices/');
export const SpendingsAPI = new SpendingsAPIResource('/spendings/');
export const AgenciesAPI = new AgenciesAPIResource('/agencies/');
export const TouristListsAPI = new TouristListsAPIResource('/addressbooks/');
export const SendPulseAPI = new SendPulseAPIResource('/sendpulse/');
export const CurrenciesAPI = new CurrenciesAPIResource('/currencies/');
export const RequestForOrdersAPI = new RequestForOrdersAPIResource('/request_for_orders/');
export const OfficeSummaryAPI = new OfficeSummaryAPIResource('/summaries/');
export const FranchiseApplicationAPI = new FranchiseApplicationAPIResource('/franchising/');
export const SubscriptionAPI = new SubscriptionAPIResource('/subscription/');
export const PicturesAPI = new PicturesAPIResource('/pictures/');
export const QuotesAPI = new QuotesAPIResource('/quotes/');
export const ContactsAPI = new ContactsAPIResource('/contacts/search/');

export default APIClient;


