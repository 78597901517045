import React, { PureComponent } from 'react';
import { IssuesAPI, PersonAPI } from '../../../api/Client/Client';
import Select from 'react-select';

import 'react-phone-input-2/lib/style.css';
import { Alert, Button, CardBody } from 'reactstrap';
import LeadInputPhone from './LeadInputPhone';
import LeadInputEmail from './LeadInputEmail';
import { getCurrentOfficeId, getUserInfo } from '../../../connect/auth';
import { Link } from 'react-router-dom';

const sourceOptions = [
  { label: 'Вайбер', value: 'viber' },
  { label: 'В контакте', value: 'vkontakte' },
  { label: 'Инстаграм', value: 'instagram' },
  { label: 'Однокласники', value: 'odnoklasniki' },
  { label: 'Телеграм', value: 'telegram' },
  { label: 'Фейсбук', value: 'facebook' },
  { label: 'WhatsApp', value: 'whatsapp' },
  { label: 'Соцсети', value: 'socials' },
  { label: 'Рассылки', value: 'mailing' },
  { label: 'Звонок в офис', value: 'call_to_office' },
  { label: 'Звонок на сотовый', value: 'call_to_phone' },
  { label: 'Посетитель', value: 'visitor' },
  { label: 'Обращение по рекомендации', value: 'recomendation' },
  { label: 'Постоянный клиент', value: 'permanent' },
  { label: 'Посетитель', value: 'visitor' },
  { label: 'Яндекс', value: 'yandex' },
  { label: 'Google', value: 'google' },
  { label: 'Сайт (чат)', value: 'geo_site_chat' },
];

/**
 * Добавление нового лида через блок статистики
 * потенциальных клиентов
 */
class LeadAddCustom extends PureComponent {
  constructor(props) {
    super(props);
    this.handleLeadAdd = this.handleLeadAdd.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleSource = this.handleSource.bind(this);
    this.handleClientChoose = this.handleClientChoose.bind(this);
    this.getBlankState = this.getBlankState.bind(this);

    // ..
    this.state = {
      name: '',
      phone: '7',
      email: '',
      comment: '',
      source: '',
      showError: false,
      showSuccess: false,
      errorMessage: {},
      mode: 'default',
      clientId: null,
    };
  }

  getBlankState() {
    this.setState({
      name: '',
      phone: '7',
      email: '',
      comment: '',
      showError: false,
      showSuccess: false,
      errorMessage: {},
      mode: 'default',
      clientId: null,
    });
  }

  handleSource(source) {
    this.setState({ source });
  }

  getValidPhone(phone) {
    return '+' + phone;
  }

  handleLeadAdd() {
    // ..
    const { name, phone, email, comment, source } = this.state;
    const data = {
      name: name,
      issues: [
        {
          text: comment,
          source: source.value,
        },
      ],
      person_info: {
        is_sms_notify: true,
        is_email_notify: true,
      },
      tag: [],
    };
    if (phone) {
      data.phone = [{ value: this.getValidPhone(phone) }];
    }
    if (email) {
      data.email = [{ value: email }];
    }

    PersonAPI.create(data)
      .then((r) => {
        if (r.person_info) {
          this.setState({
            ...this.state,
            showSuccess: true,
            showError: false,
          });
          setTimeout(() => {
            this.getBlankState();
          }, 10000);
        } else {
          throw new Error('Введите все поля формы');
        }
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          showError: true,
          showSuccess: false,
          errorMessage: err.message,
        });
      });
  }

  handleLeadAttach = () => {
    const userInfo = getUserInfo();
    let data = {
      text: this.state.comment,
      title: 'Новый запрос',
      source: this.state.source.value,
      office: getCurrentOfficeId(),
      manager: userInfo.user_id,
      type: this.state.mode,
      [this.state.mode]: this.state.clientId,
    };

    IssuesAPI.create(data).then((result) => {
      if (result.type) {
        this.setState({
          ...this.state,
          showSuccess: true,
          showError: false,
        });
        setTimeout(() => {
          this.getBlankState();
        }, 5000);
      } else {
        this.setState({
          ...this.state,
          showError: true,
          errorMessage: result,
        });
      }
    });
  };

  handleClientChoose = (client, mode) => {
    console.log(client, 'client has been chosen');
    const { phone = '-', email = '-', id: clientId } = client;
    if (mode === 'tourist') {
      const { full_name, full_name_eng } = client;
      this.setState({
        ...this.state,
        name: full_name.length > 2 ? full_name : full_name_eng,
        phone,
        email,
        mode,
        clientId,
      });
    } else if (mode === 'person') {
      const { fio } = client;
      this.setState({
        ...this.state,
        name: fio,
        phone,
        email,
        mode,
        clientId,
      });
    }
  };

  handleInput(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  render() {
    const {
      mode,
      name,
      phone,
      email,
      source,
      comment,
      clientId,
      showError,
      showSuccess,
      errorMessage,
    } = this.state;
    return (
      <CardBody>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <label htmlFor="name">Фамилия Имя Отчество</label>
              <input
                type="text"
                className="form-control"
                placeholder="Иванов Иван Иванович"
                value={name}
                name="name"
                onChange={this.handleInput}
                disabled={mode !== 'default'}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <LeadInputPhone
              value={phone}
              onChange={this.handleInput}
              onClientChoose={this.handleClientChoose}
              disabled={mode !== 'default'}
            />
          </div>
          <div className="col-sm-12">
            <LeadInputEmail
              value={email}
              onChange={this.handleInput}
              onClientChoose={this.handleClientChoose}
              disabled={mode !== 'default'}
            />
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label htmlFor="">Откуда пришел клиент</label>
              <Select
                isMulti={false}
                joinValues={true}
                simpleValue={true}
                onChange={this.handleSource}
                options={sourceOptions}
                placeholder="Источник"
                value={source}
              />
              {showError && errorMessage.source ? (
                <div className="error-message">
                  <div className="alert alert-danger" role="alert">
                    {errorMessage.source}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label htmlFor="name">Комментарий</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                name="comment"
                value={comment}
                onChange={this.handleInput}
              />
              {showError && errorMessage.text ? (
                <div className="error-message">
                  <div className="alert alert-danger" role="alert">
                    {errorMessage.text}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          {mode !== 'default' ? (
            <div className="col-sm-12">
              <Alert color="warning">
                Вы создаете запрос для{' '}
                <b>
                  <Link to={`/${mode}s/${clientId}`} target="_blank">
                    {name}
                  </Link>
                </b>
              </Alert>
            </div>
          ) : null}

          {showError && errorMessage.message ? (
            <div className="error-message">
              <div className="alert alert-danger" role="alert">
                {errorMessage.text}
              </div>
            </div>
          ) : null}

          {showSuccess ? (
            <div className="col-sm-12">
              <div className="success-message">
                <div className="alert alert-success" role="alert">
                  Запрос успешно создан
                </div>
              </div>
            </div>
          ) : null}

          <div className="col-sm-12">
            <div className="form-group">
              {mode !== 'default' ? (
                <Button color="danger" onClick={this.getBlankState}>
                  Сбросить форму
                </Button>
              ) : null}
              <button
                type="submit"
                className="float-right btn btn-sm btn-success"
                onClick={
                  mode === 'default'
                    ? this.handleLeadAdd
                    : this.handleLeadAttach
                }
              >
                Создать запрос
              </button>
            </div>
          </div>
        </div>
      </CardBody>
    );
  }
}

export default LeadAddCustom;
