import 'moment/locale/ru';
import moment from 'moment';
import React, { Component } from 'react';
import { Dropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link, Navigate } from 'react-router-dom';

import ClientAPI, {
  CalendarApi,
  POSITIVE_ACTION_STATUSES,
  UsersAPI,
} from '../../api/Client/';
import {
  getUserRole,
  deauthenticateUser,
  getUserInfo,
  getCurrentOfficeData,
  SUBAGENT,
} from '../../connect/auth';

import HeaderTitle from './header-title';
// import logger from "redux-logger";

const headerStyle = {
  paddingRight: 10,
};

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.logout = this.logout.bind(this);
    this.render_logo = this.render_logo.bind(this);
    this.render_sandwich = this.render_sandwich.bind(this);
    this.render_bell = this.render_bell.bind(this);
    this.render_avatar = this.render_avatar.bind(this);
    this.fetchUserData = this.fetchUserData.bind(this);

    this.state = {
      dropdownOpen: false,
      userInfo: getUserInfo(),
      redirectToLogin: false,
      notify: 0,
      isAuthError: false,
      balance: { value: 0, currency: 'RUB' },
      user: null,
    };
  }

  fetchUserData() {
    const user_info = getUserInfo();
    ClientAPI.getJSON(`/users/${user_info.user_id}/`).then((res) => {
      this.setState({ user: res });
    });
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  logout() {
    deauthenticateUser();
    this.setState({
      redirectToLogin: true,
    });
  }

  sidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  }

  sidebarMinimize(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-minimized');
  }

  mobileSidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  }

  asideToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('aside-menu-hidden');
  }

  componentDidMount() {
    const day = moment(new Date());
    let status;
    // TODO временный костыль для удаления старых JWT токенов.
    CalendarApi.fetchList(1, { day: day.format('YYYY-MM-DD') }, `count/`)
      .then((r) => {
        status = r.status;
        if (status === POSITIVE_ACTION_STATUSES.list) {
          return r.json();
        }
        return {};
      })
      .then((res) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          const { count, by_notification } = res;
          this.setState({ notify: count, by_notification });
        } else if (status === 401) {
          this.setState({ isAuthError: true });
        }
      });

    UsersAPI.fetchBalance()
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.retrieve) {
          this.setState({
            balance: r,
          });
        }
      });

    this.fetchUserData();
  }

  render_logo() {
    return (
      <Link to={'/'} className="navbar-brand">
        {' '}
      </Link>
    );
  }

  render_sandwich() {
    return (
      <ul className="nav navbar-nav d-md-down-none">
        <li className="nav-item">
          <button
            className="nav-link navbar-toggler sidebar-toggler"
            type="button"
            onClick={this.sidebarToggle}
          >
            &#9776;
          </button>
        </li>
      </ul>
    );
  }

  render_bell() {
    const { notify, by_notification = 0 } = this.state;

    return (
      <li className="d-md-down-none nav-item">
        <Link to={'/notify'} className="nav-link">
          <i className="icon-bell"> </i>
          <span className="badge badge-success badge-pill">
            {notify - (by_notification ? by_notification.plan : 0) }
          </span>
        </Link>
      </li>
    );
  }

  render_avatar() {
    const { dropdownOpen, userInfo, isAuthError } = this.state;
    if (isAuthError) {
      deauthenticateUser();
      return <Navigate to="/login" replace={true} />;
    }

    const userOffice = getCurrentOfficeData();

    return (
      <li className="nav-item">
        <Dropdown isOpen={dropdownOpen} toggle={this.toggle}>
          <button
            onClick={this.toggle}
            className="nav-link dropdown-toggle"
            data-toggle="dropdown"
            type="button"
            aria-haspopup="true"
            aria-expanded={dropdownOpen}
          >
            <img
              src={this.state.user?.info?.avatar}
              className="img-avatar ml-3"
              alt="avatar"
            />
            <span className="d-md-down-none">
              {!!userInfo ? userInfo.username : ``}
            </span>
          </button>

          <DropdownMenu flip={true} end={true}>
            <DropdownItem header className="text-center">
              <strong>Действия</strong>
            </DropdownItem>
            <DropdownItem>
              <Link to="/officein">
                <i className="icon-location-pin"> </i>
                {userOffice.address || userOffice.name}
              </Link>
            </DropdownItem>
            <DropdownItem>
              <Link to="/options">
                <i className="fa fa-gear"> </i> Личные настройки
              </Link>
            </DropdownItem>
            <DropdownItem onClick={this.logout}>
              <i className="icon-logout"> </i> Выход
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </li>
    );
  }

  render() {
    if (this.state.redirectToLogin) {
      return <Navigate to="/login" />;
    }
    const userRole = getUserRole();

    return (
      <header className="app-header navbar" style={headerStyle}>
        {this.render_logo()}
        {/*..sandwich..*/}
        <button
          className="navbar-toggler mobile-sidebar-toggler d-lg-none"
          type="button"
          onClick={this.mobileSidebarToggle}
        >
          &#9776;
        </button>
        <ul className="nav navbar-nav d-md-down-none">
          <li className="nav-item">
            <button
              className="nav-link navbar-toggler sidebar-toggler"
              type="button"
              onClick={this.sidebarToggle}
            >
              &#9776;
            </button>
          </li>
        </ul>
        <HeaderTitle />
        <ul className="nav navbar-nav ml-auto">
          {userRole !== SUBAGENT &&
          JSON.parse(localStorage.office).fp_type === 'subagent' ? (
            <li>
              Баланс {this.state.balance.value} {this.state.balance.currency}
            </li>
          ) : (
            ''
          )}
          {userRole !== SUBAGENT && (
            <li className="d-md-down-none nav-item">
              <Link to={'/calendar'} className="nav-link">
                <i className="icon-calendar">{''}</i>
              </Link>
            </li>
          )}
          <li className="d-md-down-none nav-item">
            <Link to={'/options'} className="nav-link">
              <i className="fa fa-gear">{''}</i>
            </Link>
          </li>

          {userRole !== SUBAGENT && this.render_bell()}
          {this.render_avatar()}
        </ul>
      </header>
    );
  }
}

export default Header;
