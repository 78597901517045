import React, { useState } from 'react';
import { CgProfile } from 'react-icons/cg';
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDroprightCircle,
} from 'react-icons/io';
import { MdOutlineContentPasteGo } from 'react-icons/md';
import { Link } from 'react-router-dom';
import {
  Badge,
  Collapse,
  ListGroupItem,
  ListGroupItemHeading,
} from 'reactstrap';

function LeadFoundClient({
  handleClientChoose,
  clients,
  name,
  clientType,
  linkTo,
  inputType,
}) {
  const [isListOpened, setListOpened] = useState(false);

  function toggleListOpened() {
    setListOpened(!isListOpened);
  }
  return (
    <ListGroupItem>
      <ListGroupItemHeading
        style={{
          display: 'flex',
          cursor: 'pointer',
          userSelect: 'none',
        }}
        onClick={toggleListOpened}
      >
        {isListOpened ? (
          <IoIosArrowDropdownCircle
            style={{ fontSize: 22, color: '#7f7f7f' }}
          />
        ) : (
          <IoIosArrowDroprightCircle
            style={{ fontSize: 22, color: '#7f7f7f' }}
          />
        )}
        <Badge
          className="badge-pill"
          color="primary"
          style={{
            fontSize: 10,
            margin: '0 5px',
            height: 14,
            alignSelf: 'center',
          }}
        >
          {clients.length}
        </Badge>
        {name}
      </ListGroupItemHeading>
      <Collapse isOpen={isListOpened}>
        <ol>
          {clients.map((client) => (
            <li
              key={client.id}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: 5,
                marginBottom: 5,
                borderBottom: '1px solid #e9e9e9',
              }}
            >
              <Link
                to={`${linkTo}/${client.id}`}
                target="_blank"
                style={{ display: 'flex', gap: 5 }}
                title="Посмотреть полные данные клиента"
              >
                <CgProfile
                  style={{
                    color: '#20a8d8',
                    cursor: 'pointer',
                    fontSize: 20,
                  }}
                />
                {client.fio
                  ? client.fio
                  : client.full_name.length > 2
                  ? client.full_name
                  : client.full_name_eng}
                <br />
                {inputType === 'phone'
                  ? client.email || 'E-mail не задан'
                  : client.phone || 'Телефон не задан'}
              </Link>
              <MdOutlineContentPasteGo
                style={{
                  fontSize: 20,
                  color: 'green',
                  cursor: 'pointer',
                }}
                onClick={() => handleClientChoose(client, clientType)}
                title="Подставить данные этого клиента в форму"
              />
            </li>
          ))}
        </ol>
      </Collapse>
    </ListGroupItem>
  );
}

export default LeadFoundClient;
