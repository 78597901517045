import React from 'react';
import { Link } from 'react-router-dom';
import { Alert, CardBody } from 'reactstrap';
import APIClient, {
  CalendarApi,
  POSITIVE_ACTION_STATUSES,
} from '../../../api/Client';
import NotifyType, {
  ARRIVED_FROM_REST,
  BIRTHDAY,
  DEPARTURE_TOMORROW,
  DURING_REST,
  PASSPORT_EXPIRES,
  PAY_EXPIRES,
  VISA_APPLICATION_DEADLINE,
} from './NotifyType';
import moment from 'moment';

export const NOTIFICATION_LANG = [
  {
    type: BIRTHDAY,
    lang: `День рождения`,
    iconClass: `fa-play`,
  },
  {
    type: DEPARTURE_TOMORROW,
    lang: `Вылет через 3 дня`,
    iconClass: `fa-play`,
  },
  {
    type: ARRIVED_FROM_REST,
    lang: `Вернулись с отдыха (2-3 день после возвращения)`,
    iconClass: `fa-play`,
  },
  {
    type: PAY_EXPIRES,
    lang: `Срок оплаты тура истекает в течение 3 дней`,
    iconClass: `fa-pause`,
  },
  {
    type: PASSPORT_EXPIRES,
    lang: `В течение 90 дней истекает срок загранпаспорта`,
    iconClass: `fa-pause`,
  },
  {
    type: VISA_APPLICATION_DEADLINE,
    lang: `В течение 2 недель истекает срок подачи документов на визу`,
    iconClass: `fa-pause`,
  },
  {
    type: DURING_REST,
    lang: `На 2 день отдыха`,
    iconClass: `fa-play`,
  },
];

export default class NotifyTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handlePreviewTemplate = this.handlePreviewTemplate.bind(this);

    this.state = {
      count: 0,
      email_template: `<p>шаблон отсутствует</p>`,
      sms_template: `шаблон отсутствует`,
      email_templates: [],
      sms_templates: [],
      isShowTemplate: false,
    };
  }

  handlePreviewTemplate(type) {
    console.log(type, this.state);
    let sms_template =
      this.state.sms_templates.length && type !== 'duringrest'
        ? this.state.sms_templates.filter(
            (template) => type == template.notification
          )[0].template
        : this.state.sms_template;
    let email_template = this.state.email_templates.length
      ? this.state.email_templates.filter(
          (template) => type == template.notification
        )[0].template
      : this.state.email_template;
    this.setState({
      ...this.state,
      email_template: email_template,
      sms_template: sms_template,
      isShowTemplate: true,
    });
  }

  componentDidMount() {
    const day = moment(new Date());
    CalendarApi.fetchList(
      1,
      { day: moment(day).format('YYYY-MM-DD') },
      `count/`
    )
      .then((r) => {
        if (r.status === POSITIVE_ACTION_STATUSES.list) {
          return r.json();
        }
      })
      .then((res) => this.setState({ count: res.count, by_notification: res.by_notification }));
    APIClient.getJSON('/templates/sms_visual/').then((res) =>
      this.setState({ sms_templates: res.results || [] })
    );
    APIClient.getJSON('/templates/email_visual/').then((res) =>
      this.setState({ email_templates: res.results || [] })
    );
  }

  render() {
    const {
      email_template,
      sms_template,
      sms_templates,
      count,
      by_notification,
      isShowTemplate,
    } = this.state;
    return (
      <div className="card">
        <div className="card-header">
          <Link to={`/notify`}>
            <i className="fa fa-align-justify mr-2"> </i>
          </Link>
          <span>
            Мои уведомления (запуск происходит один раз в день в 12.00 по МСК.)
          </span>
          <span className="float-right  pink-bg badge badge-default badge-pill">
            Всего {count - (by_notification ? by_notification.plan : 0)}
          </span>
        </div>
        <div className="card-body">
          <p>
            <i className={`fa fa-play fa-sm mr-2`}> </i> - Автоматические &nbsp;
            <i className={`fa fa-pause fa-sm mr-2`}> </i> - Принудительные
          </p>
          <ul className="list-group">
            {NOTIFICATION_LANG.map((item, idx) => (
              <li key={idx} className="justify-content-between list-group-item">
                <NotifyType
                  source={item.lang}
                  iconClass={item.iconClass}
                  type={item.type}
                  sms_templates={sms_templates}
                />
                <span
                  className="float-left clickable"
                  onClick={() => this.handlePreviewTemplate(item.type)}
                >
                  Посмотреть шаблон
                  <i className="fa fa-eye fa-4 ml-3" title={item.type} />
                </span>
              </li>
            ))}
          </ul>
        </div>
        {isShowTemplate ? (
          <CardBody>
            <Alert color="info" className="m-2">
              СМС шаблон:
            </Alert>
            <p>{sms_template}</p>

            <Alert color="info" className="m-2">
              Email шаблон:
            </Alert>
            <div dangerouslySetInnerHTML={{ __html: email_template }}></div>
            <button
              className="btn btn-success"
              onClick={() =>
                this.setState({ ...this.state, isShowTemplate: false })
              }
            >
              Закрыть
            </button>
          </CardBody>
        ) : null}
      </div>
    );
  }
}
