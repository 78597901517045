import React, { PureComponent } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import Swal from 'sweetalert';

import { POSITIVE_ACTION_STATUSES, AbandonedCustomersAPI } from '../../api/Client';
import Paginator from '../../components/Paginator';
import ProgressBar from '../../components/ProgressBar';
import { clearAllFilterParams } from '../../Utils/clearEmailfilter';

import AbandonedCustomersFilter from './components/AbandonedCustomersFilter';

class AbandonedCustomers extends PureComponent {
  constructor(props) {
    super(props);

    this.fetchGroups = this.fetchGroups.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.deleteItems = this.deleteItems.bind(this);
    this.createMail = this.createMail.bind(this);

    this.handleFetch = this.handleFetch.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleToggleItem = this.handleToggleItem.bind(this);
    this.toggleSelectAll = this.toggleSelectAll.bind(this);

    this.state = {
      count: 0,
      errors: {},
      filter: '',
      isLoading: false,
      isSuccess: false,
      items: [],
      page: 1,
      selected: [],
      groups: [],
      selectAll: false,
    };

    clearAllFilterParams();
  }

  componentDidMount() {
    const { page } = this.state;
    this.handleFetch(page);
    this.fetchGroups();
  }

  fetchGroups() {
    let status;

    AbandonedCustomersAPI.fetchList(1, {}, 'filter/groups/')
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({ groups: r || [] });
        }
      });
  }

  deleteItem(item) {
    Swal({
      title: `Вы уверены?`,
      text: `Что хотите удалить запись "${item.name}"`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const _filter = {id__in: item.id},
              page = this.state.page
        AbandonedCustomersAPI.deleteItems(_filter)
          .then((r) => {
            status = r.status;
            if (status === 400) {
              this.setState({
                errors: r.json(),
                isSuccess: false,
              });
            } else if (status === 403) {
              this.setState({
                errors: {
                  detail:
                    'У вас недостаточно прав для выполнения данного действия.',
                },
                isSuccess: false,
              });
            } else if (status == POSITIVE_ACTION_STATUSES.destroy) {
              this.setState(
                {
                  isSuccess: true,
                  errors: null,
                },
                () => this.handleFetch(page)
              );
            }
            return {};
          });
      }
    });
  }

  deleteItems() {
    const { filter, count, page, selected, selectAll } = this.state;
    let _count = selected.length ? selected.length : count,
      _filter = selected.length ? { id__in: selected.join() } : filter || {},
      status;
    if (selectAll) {
      _count = count;
      _filter = filter;
    }

    Swal({
      title: `Вы уверены?`,
      text: `Удаляем ${_count} записей`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        AbandonedCustomersAPI.deleteItems(_filter)
          .then((r) => {
            status = r.status;
            if (status === 400) {
              return r.json();
            } else if (status === 403) {
              this.setState({
                errors: {detail: 'У вас недостаточно прав для выполнения данного действия.'},
                isSuccess: false,
              });
            }
            return {};
          })
          .then((r) => {
            if (status === POSITIVE_ACTION_STATUSES.destroy) {
              this.setState({
                  errors: null,
                  isSuccess: true,
              }, () => this.handleFetch(page));
            } else if (status === 400) {
              this.setState({
                errors: r,
                isSuccess: false,
              });
            }
          });
      }
    });
  }

  handleGroupChange(item, group) {
    if (!group) {
      group = {value: ''};
    }
    if (item.group !== group.value) {
      AbandonedCustomersAPI.modify(item.id, {'group': group.value});
      const items = this.state.items.map((_item) => {
        if (_item.id === item.id){
          const updatedItem = {
            ..._item,
            group: group.value,
          }
          return updatedItem
        }
        return _item;
      })

      this.setState({items}, () => this.fetchGroups())
    }
  }

  createMail() {
    const { count, filter, selected, selectAll } = this.state;
    let _count = selected.length ? selected.length : count,
      _filter = selected.length ? { id__in: selected.join() } : filter || {};
    if (selectAll) {
      _count = count;
      _filter = filter;
    }
    Swal(
      `Создаем рассылку для раздела "Клиенты закрышихся агентов" на ${_count} записей`
    );
    localStorage.setItem('abandonedcustomers_filter', JSON.stringify(_filter));
    localStorage.setItem('abandonedcustomers_total', JSON.stringify(_count));
    localStorage.setItem('type', 'abandonedcustomers');
    return false;
  }

  handleFetch(page = 1) {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        let status;
        let { filter } = this.state;
        AbandonedCustomersAPI.fetchList(page, filter)
          .then((r) => {
            status = r.status;
            return r.json();
          })
          .then((r) => {
            let count = 0,
                items = [],
                _page = 1;
            if (status === POSITIVE_ACTION_STATUSES.list) {
              count = r.count;
              items = r.results || [];
              _page = page;
            }
            this.setState({
              count: count,
              isLoading: false,
              items: items,
              page: _page,
              selected: [],
              selectAll: false,
            });
          });
      }
    );
  }

  handleFilter(filter) {
    this.setState(
      {
        filter,
      },
      () => this.handleFetch()
    );
  }

  handlePageChange(data) {
    this.handleFetch(data.selected + 1);
  }

  handleToggleItem(item) {
    let selected = [...this.state.selected];

    if (selected.includes(item.id)) {
      selected = selected.filter((pk) => pk !== item.id);
    } else {
      selected = selected.concat(item.id);
    }
    selected.sort((a, b) => {
      return b - a;
    });

    this.setState({
      selected: selected,
      selectAll: false,
    });
  }

  toggleSelectAll() {
    const selectAll = !this.state.selectAll;
    let selected = [];
    if (selectAll && this.state.items.length) {
      selected = this.state.items.map((item) => item.id);
    }
    this.setState({
      selectAll: selectAll,
      selected: selected,
    });
  }

  render() {
    const { count, filter, isLoading, items, page, selected, selectAll } = this.state,
          selectedCount = (selectAll) ? count : selected.length;
    return (
      <div>
        <AbandonedCustomersFilter onFilter={this.handleFilter} />
        <Row>
          <Col lg="12">
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i>
                Всего <span className="badge badge-info">{count}</span>
                <Link
                  className="btn btn-success ml-3 mt-0"
                  onClick={this.createMail}
                  to="/templates/"
                  style={{ display: 'inline-block' }}
                >
                  <i className="fa fa-send mr-2"></i>
                  Создать рассылку
                </Link>
                <div className="float-right" hidden={!count}>
                  <Button
                    className="btn btn-secondary mr-2"
                    onClick={this.toggleSelectAll}
                    type="button"
                  >
                    <i className={`fa fa-${(!this.state.selectAll) ? 'check-square' : 'square'}-o mr-2`}></i>
                    {(!this.state.selectAll) ? 'Выбрать всё' : 'Снять выделение'}
                  </Button>
                </div>
                <div className="float-right" hidden={!selectedCount}>
                  <Button
                    className="btn btn-danger mr-2"
                    onClick={this.deleteItems}
                    type="button"
                  >
                    <i className="fa fa-close mr-2"></i>
                    Удалить {selectedCount}
                  </Button>
                </div>
              </CardHeader>
              {isLoading ? (
                <ProgressBar />
              ) : (
                <CardBody className="card-body_responsive">
                  <div>
                    <Table
                      striped
                      bordered
                      size="sm"
                      className="font-xs table--orange"
                    >
                      <thead>
                        <tr>
                          <th className="text-center tableItem hidden-mobile">
                            *
                          </th>
                          <th className="text-center tableItem">ФИО</th>
                          <th className="text-center tableItem">Телефоны</th>
                          <th className="text-center tableItem">Почта</th>
                          <th className="text-center tableItem">Метки</th>
                          <th className="text-center tableItem">Офис</th>
                          <th className="text-center tableItem">Тип</th>
                          <th className="text-center tableItem">Удалить</th>
                          <th className="text-center tableItem">Группа</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.length
                          ? items.map((item) => {
                            const group = (item.group) ? {value: item.group, label: item.group} : null;
                            return (
                              <tr key={item.id}>
                                <td className="text-center tableItem hidden-mobile">
                                  <input
                                    type="checkbox"
                                    onChange={() => this.handleToggleItem(item)}
                                    checked={selected.includes(item.id)}
                                  />
                                </td>
                                <td className="text-center tableItem">
                                  {item.type == 'person' ? (
                                    <Link to={`/persons/${item.related_id}`}>
                                      {item.name}
                                    </Link>
                                  ) : (
                                    <Link to={`/tourists/${item.related_id}`}>
                                      {item.name}
                                    </Link>
                                  )}
                                </td>
                                <td className="text-center tableItem">
                                  {Array.isArray(item.phone) &&
                                    item.phone
                                      .map((i) => i.value || i.origin)
                                      .join(`, `)}
                                </td>
                                <td className="text-center tableItem">
                                  {Array.isArray(item.email) &&
                                    item.email
                                      .map((i) => i.value || i.origin)
                                      .filter((i) => i)
                                      .join(`, `)}
                                </td>
                                <td className="text-center tableItem">
                                  {Array.isArray(item.tag) &&
                                    item.tag.map((tag) => (
                                      <span
                                        className="badge badge-pill badge-success ml-2"
                                        key={`item-${item.id}-tag-${tag.id}`}
                                      >
                                        {tag.name}
                                      </span>
                                    ))}
                                </td>
                                <td className="text-center tableItem">
                                  {item.office && (
                                    <span>{item.office.address}</span>
                                  )}
                                </td>
                                <td className="text-center tableItem">
                                  {item.type == 'person'
                                    ? 'Потенциальный'
                                    : 'Турист'}
                                </td>
                                <td className="text-center tableItem">
                                  <button className="btn btn-outline-danger" onClick={() => this.deleteItem(item)}>
                                    <i className="fa fa-close"></i> удалить
                                  </button>
                                </td>
                                <td className="text-center tableItem" style={{minWidth: '150px'}}>
                                  <CreatableSelect
                                    name="group"
                                    isClearable={true}
                                    placeholder="группа"
                                    closeOnSelect={true}
                                    value={group}
                                    options={this.state.groups}
                                    onChange={(group) => this.handleGroupChange(item, group)}
                                  />
                                </td>
                              </tr>
                            )})
                          : null}
                      </tbody>
                    </Table>
                  </div>
                  <Paginator
                    total={count}
                    onPageChange={this.handlePageChange}
                    forcePage={page - 1}
                  />
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AbandonedCustomers;
