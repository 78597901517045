import React, { PureComponent } from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ru';
import { POSITIVE_ACTION_STATUSES, UsersAPI } from '../../api/Client';
import {
  FRANCHISE_COUNTRY,
  FRANCHISE_SOURCE,
  FRANCHISE_SOURCE_CLASS,
  FRANCHISE_STATUS,
  FRANCHISE_STATUS_CLASS,
} from './constants';

export class FranchiseApplicationList extends PureComponent {
  constructor(props) {
    super(props);
    this.fetchManagers = this.fetchManagers.bind(this);
    this.state = {
      managers: [],
    };
  }

  fetchManagers() {
    let status;

    UsersAPI.fetchJSON(`full_list/`)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        this.setState({
          managers: status === POSITIVE_ACTION_STATUSES.list ? r : [],
        });
      });
  }

  render() {
    const { items, selected, toggleItem } = this.props;

    return (
      <Table
        striped
        bordered
        size="sm"
        className="font-xs table--orange franchise-table"
      >
        <thead>
          <tr>
            <th className="text-center tableItem">*</th>
            <th className="text-center tableItem">Номер заявки</th>
            <th className="text-center tableItem">Дата</th>
            <th className="text-center tableItem">Дата договора</th>
            <th className="text-center tableItem">Статус</th>
            <th className="text-center tableItem">Источник</th>
            <th className="text-center tableItem">Заявитель</th>
            <th className="text-center tableItem">Опыт в туризме</th>
            <th className="text-center tableItem">Офис</th>
            <th className="text-center tableItem">Страна</th>
            <th className="text-center tableItem">Менеджер</th>
            <th className="text-center tableItem">Примечание</th>
          </tr>
        </thead>
        <tbody>
          {items.length
            ? items.map((item) => (
                <tr key={item.id}>
                  <td className="text-center tableItem">
                    <input
                      type="checkbox"
                      id={`application-${item.id}`}
                      onChange={() => toggleItem(item.id)}
                      checked={selected.includes(item.id)}
                    />
                  </td>
                  <td className="text-center tableItem">
                    <Link
                      to={`/franchising/${item.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      itemID={item.id}
                    >
                      {item.id}
                    </Link>
                  </td>
                  <td className="text-center tableItem">
                    <Link
                      to={`/franchising/${item.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {moment(item.created).format('DD.MM.YY HH:mm')}
                    </Link>
                  </td>
                  <td className="text-center tableItem">
                    {item.applicant.contract_date
                      ? moment(item.applicant.contract_date).format('DD.MM.YY')
                      : null}
                  </td>
                  <td className="text-center tableItem">
                    <span className={
                      `badge badge-pill ${(FRANCHISE_STATUS_CLASS.hasOwnProperty(item.status))
                      ? FRANCHISE_STATUS_CLASS[item.status]
                      : 'badge-default'}`
                    }>
                      {FRANCHISE_STATUS.hasOwnProperty(item.status) ? FRANCHISE_STATUS[item.status] : 'не задан'}
                    </span>
                  </td>
                  <td className="text-center tableItem">
                    <span className={
                      `badge badge-pill ${(FRANCHISE_SOURCE_CLASS.hasOwnProperty(item.source))
                      ? FRANCHISE_SOURCE_CLASS[item.source]
                      : 'badge-default'}`
                    }>
                      {FRANCHISE_SOURCE.hasOwnProperty(item.source) ? FRANCHISE_SOURCE[item.source] : item.source}
                    </span>
                  </td>
                  <td className="text-center tableItem">
                    <span>{item.applicant.name}</span>
                    <br />
                    <span>{item.applicant.phone}</span>
                  </td>
                  <td className="text-center tableItem">
                    {item.applicant.have_tourism_experience ? (
                      <span>да</span>
                    ) : (
                      <span>нет</span>
                    )}
                  </td>
                  <td className="text-center tableItem">
                    {item.office.address} (
                    {item.office.city ? item.office.city.name : ''})
                  </td>
                  <td className="text-center tableItem">
                    {FRANCHISE_COUNTRY.hasOwnProperty(item.applicant.country)
                      ? FRANCHISE_COUNTRY[item.applicant.country]
                      : item.applicant.country
                    }
                  </td>
                  <td className="text-center tableItem">
                    {item.manager
                      ? item.manager.last_name + ' ' + item.manager.first_name
                      : ''}
                  </td>
                  <td
                    className="text-center tableItem"
                    style={{ minWidth: 300 }}
                  >
                    {item.comment === '' ? '---' : item.comment}
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
    );
  }
}
