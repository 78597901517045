import moment from 'moment';
import 'moment/locale/ru';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { Button } from 'reactstrap';
import ru from 'date-fns/locale/ru';

import { PersonAPI, POSITIVE_ACTION_STATUSES } from '../../../api/Client';
import { getUserRole, SUPER_ADMIN, ADMIN } from '../../../connect/auth';
import OfficeSelectContainer from '../../../components/OfficeSelectContainer/OfficeSelectContainer';
import ManagersSelectContainer from '../../../components/ManagersSelectContainer/ManagersSelectContainer';

import { ISSUE_SOURCE_MAP } from '../../Issues/constants';

const sourceOptions = Object.keys(ISSUE_SOURCE_MAP)
  .map((k) => ({
    label:
      ISSUE_SOURCE_MAP[k].name[0].toUpperCase() +
      ISSUE_SOURCE_MAP[k].name.slice(1),
    value: k,
  }))
  .sort((a, b) => a.label.localeCompare(b.label));

export const handlePersonFilters = (state) => {
  const filters = {};
  if (state.q) {
    filters.q = state.q;
  }

  if (state.tags) {
    filters.tag = state.tags.map((tag) => tag.id);
  }

  if (state.orderings) {
    filters.ordering = state.orderings.map((item) => item.value);
  }

  if (state.is_active !== null) {
    filters.is_active = state.is_active;
  }
  if (state.closedAgent !== null) {
    filters.closed_agent = state.closedAgent.value;
  }
  if (state.hasEmails !== null) {
    filters.has_emails = state.hasEmails.value;
  }

  if (state.hasPhones !== null) {
    filters.has_phones = state.hasPhones.value;
  }
  if (state.source !== null) {
    filters.source = state.source.map((item) => item.value);
  }
  if (state.managers) {
    filters.manager = state.managers.map((manager) => manager.value);
  }
  if (state.offices) {
    filters.office = state.offices.map((o) => o.id);
  }
  if (state.minCreated) {
    filters.created__gte = moment(state.minCreated).format('DD.MM.YYYY');
  }
  if (state.maxCreated) {
    filters.created__lte = moment(state.maxCreated).format('DD.MM.YYYY');
  }

  return filters;
};

class FilterPersons extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTagsOnChange = this.handleTagsOnChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.fetchTags = this.fetchTags.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.changeOffice = this.changeOffice.bind(this);
    this.changeOfficeCity = this.changeOfficeCity.bind(this);
    this.handleOrderingOnChange = this.handleOrderingOnChange.bind(this);
    this.handleActiveOnChange = this.handleActiveOnChange.bind(this);
    this.handleHasEmailsOnChange = this.handleHasEmailsOnChange.bind(this);
    this.handleHasPhonesOnChange = this.handleHasPhonesOnChange.bind(this);
    this.handleCloseAgentOnChange = this.handleCloseAgentOnChange.bind(this);
    this.handleManagersOnChange = this.handleManagersOnChange.bind(this);
    this.handleSource = this.handleSource.bind(this);
    this.minCreatedChange = this.minCreatedChange.bind(this);
    this.maxCreatedChange = this.maxCreatedChange.bind(this);

    this.state = {
      q: '',
      tags: '',
      userTags: [],
      is_active: null,
      orderings: '',
      hasEmails: null,
      hasPhones: null,
      closedAgent: null,
      offices: [],
      offices_city: null,
      source: null,
      minCreated: '',
      maxCreated: '',
    };
  }

  componentDidMount() {
    this.fetchTags();
  }

  handleSubmit() {
    this.props.onFilter(this.state);
  }

  fetchTags() {
    let status;

    PersonAPI.fetchList(1, handlePersonFilters(this.state), 'tags/')
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            userTags: r || [],
          });
        }
      });
  }

  changeOffice(offices) {
    this.setState({ offices });
  }
  changeOfficeCity(offices_city) {
    this.setState({ offices_city });
  }

  handleReset(e) {
    this.setState(
      {
        q: '',
        tags: '',
        is_active: null,
        orderings: '',
        managers: [],
        minCreated: '',
        maxCreated: '',
      },
      this.handleSubmit
    );
  }
  handleSource(source) {
    this.setState({ source });
  }
  handleManagersOnChange(managers) {
    this.setState({ managers });
  }
  handleTagsOnChange(tags) {
    this.setState({ tags });
  }

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleOrderingOnChange(orderings) {
    this.setState({ orderings });
  }
  handleHasEmailsOnChange(hasEmails) {
    this.setState({ hasEmails });
  }

  handleHasPhonesOnChange(hasPhones) {
    this.setState({ hasPhones });
  }
  handleCloseAgentOnChange(closedAgent) {
    this.setState({ closedAgent });
  }
  handleActiveOnChange(is_active) {
    this.setState({ is_active });
  }

  minCreatedChange(minCreated) {
    this.setState({ minCreated });
  }

  maxCreatedChange(maxCreated) {
    this.setState({ maxCreated });
  }

  render() {
    const {
      userTags,
      q,
      tags,
      orderings,
      hasEmails,
      hasPhones,
      offices,
      closedAgent,
    } = this.state;
    const isDisabled = this.props.disabled;
    const SORT_OPTIONS = [
      { value: 'name', label: 'Имя' },
      { value: 'last_name', label: 'Фамилия' },
      { value: 'middle_name', label: 'Отчество' },
      { value: 'email', label: 'Email-ы' },
      { value: 'phone', label: 'Телефоны' },
    ];
    const HAS_EMAILS_OPTIONS = [
      { value: 1, label: 'Есть емейлы' },
      { value: 0, label: 'Нет емейлов' },
    ];
    const HAS_PHONES_OPTIONS = [
      { value: 1, label: 'Есть телефоны' },
      { value: 0, label: 'Нет телефонов' },
    ];
    const CLOSED_AGENT_OPTIONS = [
      { value: true, label: 'Да' },
      { value: false, label: 'Нет' },
    ];
    const userRole = getUserRole();

    return (
      <div className="card filter-container">
        <div className="card-header">
          <strong>
            База ПОТЕНЦИАЛЬНЫХ клиентов, которые с нами еще не ездили, но уже
            обращались
          </strong>
        </div>

        <div className="card-body">
          <div className="filter-row">
            <div className="filter-field">
              <Select
                id="has-emails-selector"
                placeholder="Наличие email-ов"
                name="hasEmails"
                value={hasEmails}
                isSearchable={false}
                onChange={this.handleHasEmailsOnChange}
                options={HAS_EMAILS_OPTIONS}
                disabled={isDisabled}
                isClearable
              />
            </div>
            <div className="filter-field">
              <Select
                id="has-phones-selector"
                placeholder="Наличие телефонов"
                name="hasPhones"
                value={hasPhones}
                isSearchable={false}
                onChange={this.handleHasPhonesOnChange}
                options={HAS_PHONES_OPTIONS}
                disabled={isDisabled}
                isClearable
              />
            </div>
            <div className="filter-field filter__source">
              <label htmlFor="" className="col-form-label">
                Источник
              </label>
              <Select
                isMulti={true}
                joinValues={true}
                onChange={this.handleSource}
                options={sourceOptions}
                placeholder="Источник"
                value={this.state.source}
              />
            </div>
          </div>

          <div className="filter-row">
            <OfficeSelectContainer
              multi_office={true}
              selected_city={this.state.offices_city}
              selected_office={offices}
              changeCity={this.changeOfficeCity}
              changeOffice={this.changeOffice}
            >
              <div className="filter-field">
                <label htmlFor="orderingPersons">Сортировка</label>
                <Select
                  id="orderingPersons"
                  name="orderings"
                  placeholder="Сортировать"
                  isMulti={true}
                  closeOnSelect={true}
                  value={orderings}
                  onChange={this.handleOrderingOnChange}
                  options={SORT_OPTIONS}
                />
              </div>
              <div className="filter-field">
                <label htmlFor="personTags">Метки</label>
                <Select
                  id="personTags"
                  name="tag"
                  placeholder="Укажите метки"
                  isMulti={true}
                  closeOnSelect={true}
                  value={tags}
                  onChange={this.handleTagsOnChange}
                  options={userTags}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                />
              </div>
            </OfficeSelectContainer>
          </div>
          <div className="filter-row pb-0">
            Поиск клиента по номеру телефона, Email
          </div>

          <div className="filter-row">
            <div className="filter-field filter__name">
              <label htmlFor="personData">Клиент</label>
              <input
                type="text"
                className="form-control"
                id="personData"
                name="q"
                value={q}
                title="Данные клиента"
                placeholder="ФИО, email, телефон"
                onChange={this.handleInputChange}
              />
            </div>
            {(userRole === SUPER_ADMIN || userRole === ADMIN) && (
              <div className="filter-field">
                <label htmlFor="" className="col-form-label">
                  Менеджер
                </label>
                <ManagersSelectContainer
                  manager_selected={this.state.managers}
                  changeManager={this.handleManagersOnChange}
                  isMulti={true}
                  disabled={isDisabled}
                />
              </div>
            )}
            {userRole === SUPER_ADMIN && (
              <div className="filter-field">
                <label htmlFor="" className="col-form-label">
                  Закрывшийся агент
                </label>
                <Select
                  id="closed-agent-selector"
                  placeholder="Закрывшийся агентом"
                  name="closed-agent"
                  value={closedAgent}
                  isSearchable={false}
                  onChange={this.handleCloseAgentOnChange}
                  options={CLOSED_AGENT_OPTIONS}
                  disabled={isDisabled}
                  isClearable
                />
              </div>
            )}
          </div>
          <div className="filter-row">
            <div className="filter-field date-from">
            <label htmlFor="leadDate" className="col-form-label">
              Создан от
            </label>
            <DatePicker
              locale={ru}
              dateFormat="dd.MM.yyyy"
              selected={this.state.minCreated}
              selectsStart
              startDate={this.state.minCreated}
              endDate={this.state.endCreated}
              onChange={this.minCreatedChange}
              isClearable={this.state.minCreated ? true : false}
              className="d-block"
              placeholderText="Создан от"
            />
          </div>
          <div className="filter-field date-to">
            <label htmlFor="leadDate" className="col-form-label">
              Создан до
            </label>
            <DatePicker
              locale={ru}
              dateFormat="dd.MM.yyyy"
              selected={this.state.maxCreated}
              selectsEnd
              startDate={this.state.minCreated}
              endDate={this.state.maxCreated}
              onChange={this.maxCreatedChange}
              isClearable={this.state.maxCreated ? true : false}
              placeholderText="Создан до"
            />
          </div>
          </div>
          <div className="card-footer">
            <Button
              className="btn btn-sm btn-success mr-2"
              style={{ width: '120px' }}
              onClick={this.handleSubmit}
            >
              Найти
            </Button>
            <Button
              className="btn btn-sm btn-success"
              style={{
                width: '120px',
                color: '#009436',
                backgroundColor: 'transparent',
              }}
              onClick={this.handleReset}
            >
              Сбросить
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default FilterPersons;
